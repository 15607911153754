import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SafeHtmlParser } from "./safe-html-parser";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";
import { IoIosAdd, IoIosRemove } from "react-icons/io";

const FaqListing = ({ heading, description, subheading, faqs }) => {
  return (
    <section className="py-4 py-md-5 py-lg-7">
      <Container>
        <Row className="justify-content-center">
          <Col className="text-center" lg={8}>
            {subheading && (
              <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                {subheading}
              </p>
            )}
            {heading && (
              <h2 className="line-heading-dark text-center mb-4 fs-1 merriweather-regular">
                {heading}
              </h2>
            )}

            {description && (
              <div className="text-center">
                <SafeHtmlParser htmlContent={description} />
              </div>
            )}
            {faqs && faqs.length > 0 && (
              <Row className="pt-5">
                {faqs.map((faq, index) => (
                  <Col xs={12} className="my-1 mx-0 px-0 ">
                    <Accordion>
                      <Card className="border-0">
                        <Card.Header className="border-0  px-0 p-0 m-0">
                          <ContextAwareToggle
                            eventKey="0"
                            className=" border-0 "
                          >
                            <p className="pb-0 fs-5 mb-0 merriweather-bold text-black-light">
                              {faq?.title}
                            </p>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="pt-3 pb-0 px-3">
                            <p className="text-black-light text-start mb-0">
                              <SafeHtmlParser
                                htmlContent={faq?.faqFields?.faqContent}
                              />
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <hr style={{ opacity: 1, background: "rgba(1, 76, 70, 0.80)" }} />
                    </Accordion>
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const [turnArrow, setTurnArrow] = useState(false);

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback && callback(eventKey);
    setTurnArrow(!turnArrow);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header
      className="border-0 px-2"
      style={{
        color: isCurrentEventKey ? "white" : "black",
        backgroundColor: isCurrentEventKey ? "black" : "white",
      }}
      onClick={decoratedOnClick}
    >
      <Container className="pe-0 ps-1" fluid={true}>
        <Row className="align-items-center mx-0">
          <Col
            className="d-flex justify-content-start align-items-center ps-0"
            xs={11}
          >
            {children}
          </Col>
          <Col xs={1} className="d-flex px-0 justify-content-end">
            {turnArrow ? (
              <IoIosRemove className="position-relative fs-4 text-primary cheveron cheveron-minus" />
            ) : (
              <IoIosAdd className="position-relative fs-4 text-primary cheveron cheveron-plus" />
            )}
          </Col>
        </Row>
      </Container>
    </Card.Header>
  );
}

export default FaqListing;
