import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { SafeHtmlParser } from "../components/safe-html-parser";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "./googleAdsContactForm";

const HomeHero = ({
	title,
	subHeading,
	subheadingColor,
	content,
	button,
	video,
	showForm,
	reviewCount,
	defaultForm,
}) => {
	const backgroundStyle = {
		backgroundColor: `#013B3799`,
		backgroundBlendMode: "multiply",
		zIndex: 0,
	};

	return (
		<section
			style={{ overflow: "hidden" }}
			className={`position-relative bg-primary hero-wrapper ${
				showForm ? "form" : ""
			}`}
			id={showForm ? "form" : ""}
		>
			<video
				className="video"
				loop
				autoPlay
				muted
				src={video}
				playsinline
				type="video/mp4"
			>
				Sorry, your browser doesn't support HTML5 video tag.
			</video>

			<div
				className="position-absolute start-0 top-0 h-100 w-100"
				style={backgroundStyle}
			></div>
			<Container
				style={{ zIndex: 2 }}
				className="position-absolute  start-50 translate-middle top-50"
			>
				<Row className="justify-content-between">
					<Col md={6} lg={5}>
						<Row className="pt-3">
							<Col className="pl-0">
								<h1
									className="text-uppercase fs-6 pb-1 mb-0 ssp-semibold text-center text-md-start"
									style={{ color: subheadingColor }}
								>
									{subHeading ?? "Welcome to Toot Hill"}
								</h1>
								<span
									className="me-2 pt-3 mb-3 text-white text-center text-md-start display-4 d-block merriweather-light services-hero-title"
									id="services-hero-title"
								>
									{title ?? "No ordinary course"}
								</span>
								{content && (
									<div className="text-white fs-5 text-center text-md-start">
										<SafeHtmlParser htmlContent={content} />
									</div>
								)}
							</Col>
						</Row>
						<Row className="pb-1">
							<Col>
								{button && button.url && (
									<Button
										variant="primary"
										href={button.url}
										target={button.target ?? "_blank"}
										rel="noreferrer"
										className="py-3 w-100 w-md-auto mb-4 mb-md-0 px-5 me-md-4"
									>
										{button?.title}
									</Button>
								)}
							</Col>
						</Row>
						<div className="d-flex align-items-center mt-3 justify-content-center justify-content-md-start">
							<StaticImage
								quality="100"
								src="../images/5star.svg"
								alt="google"
								placeholder="blurred"
								width={100}
								formats={["auto", "webp"]}
								className="me-2 ms-2"
							/>
							<p className="text-white fs-6 py-0 my-0 me-2">
								<span className="fw-bold text-white">4.8</span>
							</p>

							<p className="text-white fs-6 py-0 my-0">
								<span className="me-1">|</span> {reviewCount} Google reviews
							</p>
						</div>
					</Col>
					{showForm && (
						<Col md={6} lg={5} className="mt-4 mt-sm-0">
							<div
								className="p-3 p-lg-5 rounded-2"
								style={{ backgroundColor: "#014C46CC" }}
							>
								<ContactForm defaultForm={defaultForm} />
							</div>
						</Col>
					)}
				</Row>
			</Container>
		</section>
	);
};

export default HomeHero;
