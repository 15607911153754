import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SafeHtmlParser } from "./safe-html-parser";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { Button } from "react-bootstrap";

const GoogleMapAdsSection = ({ sectionInfo }) => {
  return (
    <section className="py-4 py-md-5 py-lg-7">
      <Container>
        <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
          <Col lg={8}>
            {sectionInfo.subheading && (
              <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                {sectionInfo.subheading}
              </p>
            )}

            <h2 className="line-heading-dark text-center text-dark-green mb-5 fs-1 merriweather-regular">
              {sectionInfo.heading}
            </h2>
          </Col>
        </Row>

        <Row>
          <Col lg={sectionInfo?.rightHeading ? 6 : 12} className="pe-lg-3">
            <iframe
              className="position-relative google-map rounded-2"
              src={sectionInfo?.map}
              width="100%"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="mt-4">
              <p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
                Address
              </p>

              <span className="  text-dark-green mb-5 fs-4 d-block merriweather-regular">
                {sectionInfo?.address}
              </span>
            </div>
            {sectionInfo.cta && sectionInfo.cta.url && (
              <div className="text-center">
                <Button
                  style={{ borderRadius: "0px" }}
                  variant="outline-primary"
                  target={sectionInfo.cta?.target ?? "_blank"}
                  rel="noreferrer"
                  href={sectionInfo.cta?.url}
                  className="py-3 w-100  w-md-auto mb-4 mb-md-0 mt-5 px-6 "
                >
                  {sectionInfo.cta?.title}
                </Button>
              </div>
            )}
          </Col>
          <Col lg={6} className="pt-3 ps-lg-3">
            {sectionInfo?.rightHeading && (
              <h4 className="text-primary merriweather-regular">{sectionInfo?.rightHeading}</h4>
            )}
            {sectionInfo?.rightDescription && (
              <p className="text-primary text-start">
                <SafeHtmlParser htmlContent={sectionInfo?.rightDescription} />
              </p>
            )}
            {sectionInfo?.drivingDirections && (
              <div className="mt-4 pt-2">
                {sectionInfo?.drivingDirections.map((item, index) => (
                  <Accordion>
                    <Card className="border-0">
                      <Card.Header className="border-0  px-2 bg-white p-0 m-0">
                        <ContextAwareToggle eventKey="0" className=" border-0">
                          <p className="pb-0 fs-6 mb-0 merriweather-bold text-black-light">
                            {item?.title}
                          </p>
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body className="pt-2 answer-content">
                          <p className="text-black text-start mb-0">
                            <SafeHtmlParser htmlContent={item?.description} />
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <hr style={{ opacity: 1 }} className="bg-accent mb-4" />
                  </Accordion>
                ))}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const [turnArrow, setTurnArrow] = useState(false);

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback && callback(eventKey);
    setTurnArrow(!turnArrow);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header
      className="border-0"
      style={{
        color: isCurrentEventKey ? "white" : "black",
        backgroundColor: isCurrentEventKey ? "black" : "white",
      }}
      onClick={decoratedOnClick}
    >
      <Container className="pe-0 ps-1" fluid={true}>
        <Row className="align-items-center">
          <Col
            className="d-flex justify-content-start align-items-center ps-0"
            xs={11}
          >
            {children}
          </Col>
          <Col xs={1} className="d-flex px-0 justify-content-end">
            {turnArrow ? (
              <IoIosRemove className="position-relative fs-4 text-primary cheveron cheveron-minus" />
            ) : (
              <IoIosAdd className="position-relative fs-4 text-primary cheveron cheveron-plus" />
            )}
          </Col>
        </Row>
      </Container>
    </Card.Header>
  );
}

export default GoogleMapAdsSection;
